import React from 'react';
import { useMsal } from '@azure/msal-react';
import Button from 'react-bootstrap/Button';
import { IPublicClientApplication } from '@azure/msal-browser';
import AuthConfig from '../AuthConfig';

/**
 * Handles the login button click.
 * @param instance - The MSAL application instance.
 */
function handleLogin(instance: IPublicClientApplication) {
  instance.loginRedirect(AuthConfig.loginRequest).catch(e => {
    console.error(e);
  })
}

/**
 * Renders a sign in button.
 * @returns A function component that renders a sign in button.
 */
export function SignInButton() {
  const { instance } = useMsal();
  return (
    <Button variant='secondary' onClick={() => handleLogin(instance)}>Sign in</Button>
  );
}