import React from 'react';
import { PageLayout } from './components/PageLayout';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Main } from './components/Main';
import { Logout } from './components/Logout';

/**
 * Renders the app.
 * @returns A function component that renders the app.
 */
export function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PageLayout />}>
          <Route index element={<Main />} />
          <Route path="logout" element={<Logout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}