import React from 'react';
import { useMsal } from '@azure/msal-react';

/**
 * Represents the properties of the page layout component.
 */
export interface LogoutProps {
  children?: React.ReactNode;
}

/**
 * Renders a page layout.
 * @returns A function component that renders a page layout.
 */
export function Logout(props: LogoutProps) {  
  const { instance } = useMsal();
  function logout() {
    instance.logout().catch(e => {
      console.error(e);
    });
    return (<></>);
  }
  return (
    <>
      {logout()}
    </>
  )  
}