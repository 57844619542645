import { Configuration, RedirectRequest } from '@azure/msal-browser';
import configuration from './configuration/configuration.json';

/**
 * Represents a B2C policy and configuration values. 
 */
interface B2CPolicy {
  /**
   * Name of the policy.
   */
  name: string;

  /**
   * Authority URL of the policy.
   */
  authority: string;
}
/**
 * Represents all the B2C policies and their configuration values.
 */
interface B2CPolicies {
  /**
   * Sign in B2C policy.
   */
  signInPolicy: B2CPolicy;

  /**
   * Domain
   */
  domain: string;
}

/**
 * Contains all the authentication configurations required by the application.
 */
export default class AuthConfig {
  static b2cConfig: B2CPolicies = {
    signInPolicy: {
      name: configuration.B2CPolicyName, 
      authority: `https://${configuration.CustomDomain}/${configuration.TenantName}.onmicrosoft.com/${configuration.B2CPolicyName}`  
    },
    domain: configuration.CustomDomain
  };
  
  /**
   * MSAL instance configuration values.
   */
  static msalConfig: Configuration = {
    auth: {
      clientId: configuration.AppClientId, 
      authority: this.b2cConfig.signInPolicy.authority, 
      knownAuthorities: [this.b2cConfig.domain], 
	  redirectUri: configuration.redirectUri,
	  postLogoutRedirectUri: configuration.postLogoutRedirectUri
    }
  };

  /**
   * Login request properties.
   */
  static loginRequest: RedirectRequest = {
    scopes: ['openid']
  };
}