import React from 'react';
import {v4 as uuidv4} from 'uuid';
import configuration from '../configuration/configuration.json';

/**
 * Renders a list of menu options.
 * @returns A function component that renders a a list of menu options.
 */
export function MenuOptions() {
  const baseUrl = `https://${configuration.CustomDomain}/${configuration.TenantName}.onmicrosoft.com/oauth2/v2.0/authorize`;
  const mfaEnrolmentUrl = `${baseUrl}?p=${configuration.B2CMFAEnrolmentPolicyName}&client_id=${configuration.AppClientId}&nonce=defaultNonce&redirect_uri=${encodeURIComponent(configuration.redirectUri)}&scope=openid&response_type=code&code_challenge=${uuidv4()}`;
  return (
    <>
      <a href={mfaEnrolmentUrl} target='_blank'>Update MFA Enrolment</a>
    </>
  );
}