import React from 'react';
import { EventMessage, EventType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import AuthConfig from "../AuthConfig";
import { isAuthenticationResult } from "../utilities/typeCheckFunctions";

/**
 * Renders the account handler that manages MSAL callback events.
 * @returns A function component that renders an account handler.
 */
export function AccountHandler() {
  const { instance } = useMsal();
  useEffect(() => {
    const callbackId = instance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        if (event?.payload && isAuthenticationResult(event.payload) && event.payload.idTokenClaims) {
          const idTokenClaims: any = event.payload.idTokenClaims;
          if (idTokenClaims['acr'] === AuthConfig.b2cConfig.signInPolicy.name.toLowerCase()) {
            instance.setActiveAccount(event.payload.account);
          }
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);
  return (<></>);
}