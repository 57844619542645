import React from 'react';
import { Container, Navbar, Alert } from 'react-bootstrap';
import { Outlet } from "react-router-dom";
import { SignInButton } from './SignInButton';
import { SignOutButton } from './SignOutButton';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { AccountHandler } from './AccountHandler';
import { MenuOptions } from './MenuOptions';

/**
 * Renders a page layout.
 * @returns A function component that renders a page layout.
 */
export function PageLayout() {  
  return (
    <>
      <AccountHandler />
      <Navbar bg='primary' variant='dark'>
        <Container>
          <Navbar.Brand href='/'>Test Login Application</Navbar.Brand>
          <Navbar.Text >
            <UnauthenticatedTemplate>
              <SignInButton />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              Signed in as: <SignOutButton /> | <MenuOptions />
            </AuthenticatedTemplate>
          </Navbar.Text>
        </Container>        
      </Navbar>
      <Container style={{ marginTop: '2rem' }}>
        <h1>Welcome to the Test Login Application</h1>
        <UnauthenticatedTemplate>
          <Alert variant='warning' style={{ marginTop: '2rem' }}>The page is currently unauthenticated. Click the Sign In button to authenticate.</Alert>
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <Outlet />
        </AuthenticatedTemplate>
      </Container>      
    </>
  )  
}