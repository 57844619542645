import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Table } from 'react-bootstrap';

/**
 * Represents the properties of the page layout component.
 */
export interface MainProps {
  children?: React.ReactNode;
}

/**
 * Renders a page layout.
 * @returns A function component that renders a page layout.
 */
export function Main(props: MainProps) {
  const { instance } = useMsal();
  // Iterates over a list to display values on seperate rows under the one property 
  function formatRowSpan(claimName: string, claimValues: Array<string> = []) {
    const rows: Array<JSX.Element> = [];
    for (let i = 0; i < claimValues.length; i++) {
      const property = i === 0 ? <td style={{ verticalAlign: 'middle' }} rowSpan={claimValues.length}><strong>{claimName}</strong></td> : null;
      const value = <td>{claimValues[i]}</td>;
      const row = <tr>
        {property}
        {value}
      </tr>;
      rows.push(row);
    }
    return (rows);
  }
  function getIdTokenValues() {
    const currentAccount = instance.getActiveAccount();
    if (currentAccount && currentAccount.idTokenClaims) {
      const claims: Array<JSX.Element> = [];
      for (const claimProperty in currentAccount.idTokenClaims) {
        if (claimProperty === 'groups') {
          const groupRow = formatRowSpan(claimProperty, currentAccount.idTokenClaims[claimProperty] as Array<string>);
          claims.push(...groupRow);
        }
        else {
          const value = currentAccount.idTokenClaims[claimProperty] as string;
          claims.push(<tr><td><strong>{claimProperty}</strong></td><td>{value}</td></tr>);
        }
      }
      return <>{claims}</>;
    }
    return (<></>);
  }
  return (
    <>
      <h2>ID Token Claims</h2>
      <Table style={{ marginTop: '2rem' }} bordered striped >
        <thead>
          <tr>
            <th><strong>Claim Name</strong></th>
            <th><strong>Value</strong></th>
          </tr>
        </thead>
        <tbody>
          {getIdTokenValues()}
        </tbody>
      </Table>
    </>
  )
}