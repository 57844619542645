import React from 'react';
import { useMsal } from '@azure/msal-react';
import Button from 'react-bootstrap/Button';
import { IPublicClientApplication } from '@azure/msal-browser';

/**
 * Handles the logout button click.
 * @param instance - The MSAL application instance.
 */
function handleLogout(instance: IPublicClientApplication) {
  instance.logoutRedirect().catch(e => {
    console.error(e);
  })
}

/**
 * Renders a sign out button.
 * @returns A function component that renders a sign out button.
 */
export function SignOutButton() {
  const { instance } = useMsal();  
  const getFullName = () => {
    const currentAccount = instance.getActiveAccount();
    if (currentAccount && currentAccount.idTokenClaims) {
      const firstName: string = currentAccount && currentAccount.idTokenClaims ? currentAccount.idTokenClaims['given_name'] as string : '';
      const lastName: string = currentAccount && currentAccount.idTokenClaims ? currentAccount.idTokenClaims['family_name'] as string : '';
      return `${firstName} ${lastName}`;
    }
    return '';
  };
  return (
    <Button variant='link' className='navbar-text' style={{ padding: 0, verticalAlign: 'baseline' }} onClick={() => handleLogout(instance)}>{getFullName()}</Button>
  );
}